import { createLocalVue, mount } from "@vue/test-utils";

import Vuex from "vuex";
import i18next from "@/i18next";
import { BImg } from "bootstrap-vue";

import { getStoreValues } from "../Shared/utils";

import MyDetails from "../MyDetails.vue";
import General from "../MyDetails/General.vue";
import LearningInterest from "../MyDetails/LearningInterest.vue";
import ProfessionalInformation from "../MyDetails/ProfessionalInformation.vue";
import ProfilePicture from "../MyDetails/ProfilePicture.vue";

// create an extended `Vue` constructor
const localVue = createLocalVue();
localVue.use(Vuex);

localVue.component("b-img", BImg);

describe("MyDetails.vue", () => {
  it("render when created ", async () => {
    let wrapper = await getWrapper();
    expect(wrapper.exists()).toBeTruthy();
    expect(wrapper.element).toMatchSnapshot();
    wrapper.destroy();
  });

  it("should render the children components", async () => {
    let wrapper = await getWrapper();
    const general = wrapper.findComponent(General); // => finds Bar by component instance
    expect(general.exists()).toBe(true);
    const learning_interest = wrapper.findComponent(LearningInterest); // => finds Bar by component instance
    expect(learning_interest.exists()).toBe(true);
    const professional_info = wrapper.findComponent(ProfessionalInformation); // => finds Bar by component instance
    expect(professional_info.exists()).toBe(true);
    const profile_picture = wrapper.findComponent(ProfilePicture); // => finds Bar by component instance
    expect(profile_picture.exists()).toBe(true);
  });
});

function getWrapper() {
  let store = getStoreValues();

  let wrapper = mount(MyDetails, {
    localVue,
    store,
    i18n: i18next
  });

  return wrapper;
}
